const historyTimeouts = {}

async function add(editorSDK, appToken, label) {
    await editorSDK.history.add(appToken, {label})
}

async function addWithDelay(editorSDK, appToken, label, delay) {
    if (historyTimeouts[label]) {
        clearTimeout(historyTimeouts[label])
    }

    historyTimeouts[label] = setTimeout(() => add(editorSDK, appToken, label), delay)
}

export {add, addWithDelay}
