import * as constants from './constants'
import * as menus from './wrappers/menus'
import * as routers from './wrappers/routers'
import {cloneDeep as cloneDeep_} from 'lodash'
import {log} from '../utils/monitoring'
import {areAppWidgetsEnabled} from '../utils/experiments'
import {getIsResponsiveEditor} from './services/applicationState'

const applicationState = {
    pageDependencies: {}
}

const getApplicationComponents = async (editorSDK, appToken) => {
    const _menus = []
    const menuIds = menus.getMenuIds()
    const [sospContainer, pagesGroupsRefs, _routers, controllers] = await Promise.all([
        editorSDK.components.getById(appToken, {id: constants.SOSP_CONTAINER_CUSTOM_ID}),
        editorSDK.pagesGroup.getAll(appToken),
        routers.getAll(editorSDK, appToken),
        editorSDK.controllers.listControllers(appToken, {})
    ])

    for (let menuId of Object.values(menuIds)) {
        const menu = await editorSDK.menu.getById(appToken, {menuId})
        if (menu) {
            _menus.push(menu)
        }
    }

    return {
        _routers,
        controllers,
        _menus,
        sospContainer,
        pagesGroupsRefs,
    }
}

async function isReady(editorSDK, appToken, {shouldLog = false} = {}) {
    const hasAppWidgets = getIsResponsiveEditor() || await areAppWidgetsEnabled()
    const {
        _routers,
        controllers,
        _menus,
        sospContainer,
        pagesGroupsRefs,
    } = await getApplicationComponents(editorSDK, appToken)

    if (
        shouldLog &&
        _routers.length === 2 &&
        (hasAppWidgets ? true : controllers.length === 1) &&
        _menus.length >= 2 &&
        !sospContainer
    ) {
        log(
            'SOSP container is not apparent when fixing broken installation, MA would be deleted but is not because all other components are apparent',
            {extra: {pagesGroupsRefs: JSON.stringify(pagesGroupsRefs)}},
        )
    }

    // Failed installations might have all the components but no pages
    const hasPages = _routers.length === 2 && _routers[0].pages.length > 0 && _routers[1].pages.length > 0

    return (
        _routers.length === 2 &&
        (hasAppWidgets ? true : controllers.length === 1) &&
        _menus.length >= 2 &&
        (sospContainer ? pagesGroupsRefs.length === 1 : hasPages)
    )
}

async function isEmpty(editorSDK, appToken) {
    const {
        _routers,
        controllers,
        _menus,
        sospContainer,
        pagesGroupsRefs,
    } = await getApplicationComponents(editorSDK, appToken)

    return (
        _routers.length === 0 &&
        controllers.length === 0 &&
        _menus.length === 0 &&
        !sospContainer &&
        pagesGroupsRefs.length === 0
    )
}

async function isOnlyPageGroupApparent(editorSDK, appToken) {
    const {
        _routers,
        controllers,
        _menus,
        sospContainer,
        pagesGroupsRefs,
    } = await getApplicationComponents(editorSDK, appToken)

    return (
        _routers.length === 0 &&
        controllers.length === 0 &&
        _menus.length === 0 &&
        !sospContainer &&
        pagesGroupsRefs.length === 1
    )
}

export async function isApplicationReady(editorSDK, appToken, props) {
    return await isReady(editorSDK, appToken, props)
}

export function getPageDependencies() {
    return cloneDeep_(applicationState.pageDependencies)
}

export function setPageDependencies(newDependencies) {
    return (applicationState.pageDependencies = newDependencies)
}

export {isEmpty, isOnlyPageGroupApparent, getApplicationComponents}
