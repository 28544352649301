async function create(editorSDK, appToken, groupName) {
    const memberPagesGroup = await editorSDK.pagesGroup.getByGroupName(appToken, {groupName})
    if (memberPagesGroup) {
        await editorSDK.pagesGroup.remove(appToken, {pagesGroupPointer: memberPagesGroup})
    }
    await editorSDK.pagesGroup.create(appToken, {groupName})
}

async function addComponentToGroup(editorSDK, appToken, groupName, componentPointer) {
    const pagesGroupPointer = await editorSDK.pagesGroup.getByGroupName(appToken, {groupName})
    await editorSDK.components.modes.showComponentOnlyOnPagesGroup(appToken, {componentPointer, pagesGroupPointer})
}

async function addPageToGroup(editorSDK, appToken, groupName, pageId) {
    const pagesGroupPointer = await editorSDK.pagesGroup.getByGroupName(appToken, {groupName})
    await editorSDK.pagesGroup.addPageToPagesGroup(appToken, {pagesGroupPointer, pageId})
}

async function remove(editorSDK, appToken) {
    const pagesGroupsRefs = await editorSDK.pagesGroup.getAll(appToken)
    for (let pageGroupRef of pagesGroupsRefs) {
        await editorSDK.pagesGroup.remove(appToken, {pagesGroupPointer: pageGroupRef})
    }
}

export {addComponentToGroup, addPageToGroup, create, remove}
