import * as tpa from '../wrappers/tpa'
import * as constants from '../constants'
import * as experiments from '../../utils/experiments'
import * as componentsWrapper from '../wrappers/components'
import {getIsADI} from './applicationState'

const isMyAccountLayoutHorizontal = async ({editorSDK}) => {
    const isHorizontalLayoutEnabled = !getIsADI() && await experiments.isHorizontalLayoutEnabled()

    if (!isHorizontalLayoutEnabled) {
        return false
    }

    try {
        const {applicationId} = await tpa.getDataByAppDefId({editorSDK, appDefinitionId: constants.MY_ACCOUNT_APP_DEF_ID})
        const myAccountComps = await tpa.getAllCompsByApplicationId({editorSDK, applicationId})
        const myAccountCompRef = await componentsWrapper.getById({editorSDK, id: myAccountComps[0].id})
        const myAccountLayout = await componentsWrapper.getComponentLayout({editorSDK, componentRef: myAccountCompRef})
        const isHorizontalLayout = myAccountLayout.x < 100
        return isHorizontalLayout
    } catch (e) {
        return !getIsADI() || await experiments.isHorizontalLayoutEnabled()
    }
}

export {isMyAccountLayoutHorizontal}
