import i18next from 'i18next'
import i18nextXHRBackend from 'i18next-xhr-backend'

export default async function(baseUrl, locale) {
  return new Promise((resolve, reject) => {
    i18next.use(i18nextXHRBackend).init(
      {
        lng: locale,
        fallbackLng: 'en',
        keySeparator: '$',
        interpolation: {
          escapeValue: false
        },
        backend: {
          loadPath: `${baseUrl}assets/locale/Members/Members_{{lng}}.json`,
          crossDomain: true
        },
        react: {
          wait: true
        }
      },
      err => (err ? reject(err) : resolve(i18next))
    )
  })
}
