const getPageRoles = (router) => Object
    .keys(router.config.patterns)
    .map(pattern => router.config.patterns[pattern])
    .filter(pageConfig => pageConfig.appData && !pageConfig.appData.appDefinitionId)
    .map(pageConfig => pageConfig.page)

const getCustomPages = async (editorSDK) => {
    const pages = []
    const routerPages = await editorSDK.document.routers.getAll()

    routerPages.forEach(router => {
        if (!router.config.patterns) {
            return
        }

        const pageRoles = getPageRoles(router)

        router.pages.forEach(page => {
            if (pageRoles.includes(page.pageRoles[0])) {
                pages.push({pageRef: page.pageRef, role: page.pageRoles[0]})
            }
        })
    })

    return pages
}

export {getCustomPages}
