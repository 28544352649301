const RTL_LOCALES = ['he', 'ar']

const isRtlLocale = (locale) => RTL_LOCALES.includes(locale)

const getEditorLocale = async (editorSDK, token) => {
    editorSDK = editorSDK || window.editorSDK
    return await editorSDK && editorSDK.environment && editorSDK.environment.getLocale(token)
}

const getSiteLocale = async (editorSDK, token) => {
    editorSDK = editorSDK || window.editorSDK
    return await editorSDK && editorSDK.info && editorSDK.info.getLanguage(token)
}

export {isRtlLocale, getEditorLocale, getSiteLocale}
