import * as routersService from './routers'
import * as menusService from './menus'
import * as pagesWrapper from '../wrappers/pages'
import * as pagesGroupWrapper from '../wrappers/pagesGroup'
import {MEMBERS_PAGES_GROUP_NAME, SANTA_MEMBERS_APP_ID, UNDELETABLE_PAGES} from '../constants'

async function setProtectedPage({editorSDK, appToken, page}) {
    const existingPageData = await pagesWrapper.getPageData({appToken, editorSDK, pageRef: page.pageData.pageRef})
    if (existingPageData && existingPageData.pageSecurity && !existingPageData.pageSecurity.requireLogin) {
        await pagesWrapper.updatePageData({
            editorSDK,
            appToken,
            pageRef: page.pageData.pageRef,
            pageData: {pageSecurity: {requireLogin: true}}
        })
    }
}

function setProtectedPages({editorSDK, appToken, pages}) {
    return Promise.all(pages.map(page => setProtectedPage({editorSDK, appToken, page})))
}

function addPagesToPagesGroup({editorSDK, appToken, pages}) {
    return Promise.all(pages.map(page => pagesGroupWrapper.addPageToGroup(editorSDK, appToken, MEMBERS_PAGES_GROUP_NAME, page.pageData.pageRef.id)))
}

function updatePageWithManagingAppDefId({editorSDK, pageRef}) {
    return pagesWrapper.updatePageData({editorSDK, pageRef, pageData: {managingAppDefId: SANTA_MEMBERS_APP_ID}})
}

async function connectPagesToMembers({editorSDK, appToken, pages}) {
    const {publicRouter, privateRouter} = await routersService.getMembersAreaRouters({editorSDK, appToken})
    const privatePages = pages.filter(page => page.pageData.isPrivate)
    const publicPages = pages.filter(page => !page.pageData.isPrivate)
    const includesPrivatePages = privatePages.length > 0
    const includesPublicPages = publicPages.length > 0

    await Promise.all([
        includesPrivatePages && routersService.connectPagesToRouter({editorSDK, appToken, pages: privatePages, router: privateRouter}),
        includesPublicPages && routersService.connectPagesToRouter({editorSDK, appToken, pages: publicPages, router: publicRouter}),
        includesPrivatePages && setProtectedPages({editorSDK, appToken, pages: privatePages}),
        addPagesToPagesGroup({editorSDK, appToken, pages}),
    ].filter(p => !!p))

    return menusService.connectPagesToMenus({editorSDK, appToken, pages})
}

async function setStateForPages(editorSDK) {
    const applicationPages = await editorSDK.document.pages.getApplicationPages('')

    const readOnly = []
    const deletable = []

    applicationPages.forEach((pageData) => {
        const pageRef = {id: pageData.id, type: "DESKTOP"};
        if (UNDELETABLE_PAGES && UNDELETABLE_PAGES.includes(pageData.tpaPageId)) {
            readOnly.push(pageRef)
        } else {
            deletable.push(pageRef)
        }
    })
    await editorSDK.document.pages.setState('', {
        state: {
            readOnly,
            deletable
        }
    })
    return editorSDK.document.application.reloadManifest()
}

export {
    connectPagesToMembers,
    updatePageWithManagingAppDefId,
    setStateForPages
}
