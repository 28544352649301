import {CLASSIC_EDITOR_MAIN_SECTION_WIDTH} from '../constants'


const MENU_APP_WIDGET_RESPONSIVE_LAYOUT = {
    type: 'LayoutData',
    componentLayouts: [{
        type: 'ComponentLayout',
        height: {type: 'px', value: 40},
        width: {type: 'percentage', value: 100},
        minHeight: {type: 'px', value: 40},
        maxWidth: {type: 'px', value: 974}
    }],
    itemLayouts: [{
        type: 'GridItemLayout',
        gridArea: {
            rowStart: 2,
            columnStart: 1,
            rowEnd: 3,
            columnEnd: 2,
        },
        justifySelf: 'center',
        alignSelf: 'start',
        margins: {
            left: {type: 'px', value: 0},
            top: {type: 'px', value: 0},
            right: {type: 'px', value: 0},
            bottom: {type: 'px', value: 0}
        },
    }],
    containerLayouts: [{
        type: 'GridContainerLayout',
        rows: [{type: 'fr', value: 1}],
        columns: [{type: 'fr', value: 1}],
    }]
}

const MENU_APP_WIDGET_CONNECTIONS = {
    type: 'ConnectionList',
    items: [
        {
            type: 'ConnectionItem',
            controllerId: 'members-menu-controller-id',
            isPrimary: true,
            config: '{}',
            role: 'members-menu'
        }
    ]
}

const MENU_COMP_RESPONSIVE_STYLE = {
    type: 'StylesInBreakpoint',
    skin: 'wysiwyg.common.components.dropdownmenu.viewer.skins.OverlineMenuButtonSkin',
    stylesInBreakpoints: [
        {
            styleType: 'custom',
            type: 'ComponentStyle',
            skin: 'wysiwyg.common.components.dropdownmenu.viewer.skins.OverlineMenuButtonSkin',
            componentClassName: 'wysiwyg.viewer.components.menus.DropDownMenu',
            style: {
                // Theme or direct values
                properties: {
                    'boxShadowToggleOn-shd': 'true',
                    'alpha-bgDrop': '1',
                    'alpha-brdh': '1',
                    'alpha-brd': '0',
                    'alpha-brds': '1',
                    bgDrop: 'color_11',
                    brd: 'color_15',
                    brdh: 'color_17',
                    brds: 'color_18',
                    fnt: 'font_8',
                    menuTotalBordersX: '0',
                    pad: '10',
                    rd: '0px',
                    shd: '0px 1px 4px 0px rgba(0,0,0,0.2)',
                    txt: 'color_15',
                    txth: 'color_17',
                    txts: 'color_18',
                },
                // Specifies whether value is taken from theme or directly
                propertiesSource: {
                    'boxShadowToggleOn-shd': 'value',
                    'alpha-bgDrop': 'value',
                    'alpha-brd': 'value',
                    'alpha-brdh': 'value',
                    'alpha-brds': 'value',
                    bgDrop: 'theme',
                    brd: 'theme',
                    brdh: 'theme',
                    brds: 'theme',
                    fnt: 'theme',
                    menuTotalBordersX: 'value',
                    pad: 'value',
                    rd: 'value',
                    shd: 'value',
                    txt: 'theme',
                    txth: 'theme',
                    txts: 'theme'
                }
            }
        }
    ]
}

const MENU_COMP_DEF_HORIZONTAL = {
    type: 'Component',
    data: {
        type: 'CustomMenuDataRef',
        metaData: {isPreset: true, schemaVersion: '1.0', isHidden: false},
        menuRef: '#MEMBERS_SUB_MENU',
        role: 'members_menu'
    },
    componentType: 'wysiwyg.viewer.components.menus.DropDownMenu',
    layout: {
        width: CLASSIC_EDITOR_MAIN_SECTION_WIDTH,
        height: 40,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
        x: 0,
        y: 0
    },
    layoutResponsive: {
        type: 'LayoutData',
        componentLayouts: [{
            type: 'ComponentLayout',
            height: {type: 'px', value: 40},
            minHeight: {type: 'px', value: 40}
        }],
        itemLayouts: [{
            type: 'GridItemLayout',
            gridArea: {
                rowStart: 1,
                columnStart: 1,
                rowEnd: 2,
                columnEnd: 2
            },
            justifySelf: 'stretch',
            alignSelf: 'start',
            margins: {
                left: {type: 'px', value: 0},
                top: {type: 'px', value: 0},
                right: {type: 'px', value: 0},
                bottom: {type: 'px', value: 0}
            },
        }],
        containerLayouts: []
    },
    style: MENU_COMP_RESPONSIVE_STYLE,
    props: {
        type: 'HorizontalMenuProperties',
        metaData: {schemaVersion: '1.0'},
        alignButtons: 'left',
        alignText: 'left',
        sameWidthButtons: false,
        stretchButtonsToMenuWidth: false,
        moreButtonLabel: 'More',
        moreItemHeight: 15
    },
}

const MEMBERS_MENU_RESPONSIVE_APP_WIDGET_PROPS = {
    componentType: 'platform.components.AppWidget',
    type: 'Container',
    layoutResponsive: MENU_APP_WIDGET_RESPONSIVE_LAYOUT,
    style: {
        skin: 'platform.components.skins.AppWidgetSkin',
        style: {}
    },
    layout: {
        width: CLASSIC_EDITOR_MAIN_SECTION_WIDTH,
        height: 40,
        x: 12,
        y: 250,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false
    },
    data: {
        type: 'AppController',
        applicationId: '14cc59bc-f0b7-15b8-e1c7-89ce41d0e0c9',
        name: 'members-menu-controller',
        controllerType: 'members-menu',
        id: 'members-menu-controller-id'
    }
}

const MENU_COMP_DEF_HORIZONTAL_RTL = {
    ...MENU_COMP_DEF_HORIZONTAL,
    props: {
        ...MENU_COMP_DEF_HORIZONTAL.props,
        itemsAlignment: 'right',
        alignButtons: 'right',
        alignText: 'right',
    }
}

const MEMBERS_MENU_RESPONSIVE_APP_WIDGET = {
    ...MEMBERS_MENU_RESPONSIVE_APP_WIDGET_PROPS,
    components: [{
        ...MENU_COMP_DEF_HORIZONTAL,
        connections: MENU_APP_WIDGET_CONNECTIONS
    }]
}

const MEMBERS_MENU_RESPONSIVE_APP_WIDGET_RTL = {
    ...MEMBERS_MENU_RESPONSIVE_APP_WIDGET_PROPS,
    components: [{
        ...MENU_COMP_DEF_HORIZONTAL_RTL,
        connections: MENU_APP_WIDGET_CONNECTIONS
    }]
}

export {MEMBERS_MENU_RESPONSIVE_APP_WIDGET, MEMBERS_MENU_RESPONSIVE_APP_WIDGET_RTL}
