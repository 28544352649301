import Experiments from '@wix/wix-experiments'

let conductedExperiments
let conductedCashierExperiments

async function maybeConductExperiments() {
    if (!conductedExperiments) {
        conductedExperiments = new Experiments({scope: 'members-area'})
        await conductedExperiments.ready()
    }
}

async function maybeConductCashierExperiments() {
    if (!conductedCashierExperiments) {
        conductedCashierExperiments = new Experiments({scope: 'cashier'})
    }

    await conductedCashierExperiments.ready()
}

async function isHorizontalLayoutEnabled() {
    await maybeConductExperiments()
    return conductedExperiments.get('specs.membersArea.HorizontalLayout') === 'true'
}

async function areAppWidgetsEnabled() {
    await maybeConductExperiments()
    return conductedExperiments.get('specs.membersArea.AppWidgets') === 'true'
}

async function isMA177FixEnabled() {
    await maybeConductExperiments()
    return conductedExperiments.get('specs.membersArea.MA177Fix') === 'true'
}

async function isOB19052FixEnabled() {
    await maybeConductExperiments()
    return conductedExperiments.get('specs.membersArea.OB19052Fix') === 'true'
}

async function isManageMemberPagesEnabled() {
    await maybeConductExperiments()
    return conductedExperiments.get('specs.membersArea.ManageMemberPages') === 'true'
}

async function isPlatformisedPagesPanelEnabled() {
    await maybeConductExperiments()
    return conductedExperiments.get('specs.membersArea.platformisedPagesPanel') === 'true'
}

async function isPlatformPagesRemoveEnabled() {
    await maybeConductExperiments()
    return conductedExperiments.get('specs.membersArea.PlatformPageRemove') === 'true'
}

async function isSaveCreditCardEnabled() {
    await maybeConductCashierExperiments()
    return conductedCashierExperiments.get('specs.cashier.SaveCreditCard') === 'true'
}

async function isEnableWalletAutoDetectingEnabled() {
    await maybeConductCashierExperiments()
    return conductedCashierExperiments.get('specs.payments.EnableWalletAutoDetecting') === 'true'
}

export {
    isHorizontalLayoutEnabled,
    areAppWidgetsEnabled,
    isMA177FixEnabled,
    isOB19052FixEnabled,
    isManageMemberPagesEnabled,
    isPlatformisedPagesPanelEnabled,
    isPlatformPagesRemoveEnabled,
    isSaveCreditCardEnabled,
    isEnableWalletAutoDetectingEnabled
}
