const LOGIN_COMP_APP_WIDGET = {
    componentType: 'platform.components.AppWidget',
    style: {
        skin: 'platform.components.skins.AppWidgetSkin',
        style: {}
    },
    type: 'Container',
    layout: {
        width: 120,
        height: 40,
        x: 850,
        y: 24,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false
    },
    layoutResponsive: {
        type: 'LayoutData',
        componentLayouts: [{
            type: 'ComponentLayout',
            width: {type: 'px', value: 120},
            height: {type: 'px', value: 40},
            minHeight: {type: 'px', value: 40}
        }],
        itemLayouts: [{
            type: 'GridItemLayout',
            gridArea: {
                rowStart: 1,
                columnStart: 1,
                rowEnd: 2,
                columnEnd: 2,
            },
            justifySelf: 'end',
            alignSelf: 'center',
            margins: {
                left: {type: 'px', value: 0},
                top: {type: 'px', value: 0},
                right: {type: 'px', value: 70},
                bottom: {type: 'px', value: 0}
            },
        }],
        containerLayouts: [{
            type: 'GridContainerLayout',
            rows: [{type: 'fr', value: 1}],
            columns: [{type: 'fr', value: 1}],
        }]
    },
    data: {
        type: 'AppController',
        applicationId: '14cc59bc-f0b7-15b8-e1c7-89ce41d0e0c9',
        name: 'login-bar-controller',
        controllerType: 'members-login-bar',
        id: 'login-bar-controller-id'
    },
}

const LOGIN_COMP_RESPONSIVE_LAYOUT = {
    type: 'LayoutData',
    componentLayouts: [{
        type: 'ComponentLayout',
        width: {type: 'px', value: 120},
        height: {type: 'px', value: 40},
        minHeight: {type: 'px', value: 40}
    }],
    itemLayouts: [{
        type: 'GridItemLayout',
        gridArea: {
            rowStart: 1,
            columnStart: 1,
            rowEnd: 2,
            columnEnd: 2
        },
        justifySelf: 'stretch',
        alignSelf: 'start',
        margins: {
            left: {type: 'px', value: 0},
            top: {type: 'px', value: 0},
            right: {type: 'px', value: 0},
            bottom: {type: 'px', value: 0}
        },
    }],
    containerLayouts: []
}

const LOGIN_COMP_RESPONSIVE_STYLE = {
    type: 'StylesInBreakpoint',
    skin: 'wysiwyg.viewer.skins.LoginSocialBarSkin',
    stylesInBreakpoints: [
        {
            type: 'ComponentStyle',
            skin: 'wysiwyg.viewer.skins.LoginSocialBarSkin',
            componentClassName: 'wysiwyg.viewer.components.LoginSocialBar',
            styleType: 'custom',
            style: {
                properties: {
                    'alpha-bg': '0',
                    'alpha-brd': '1',
                    'alpha-brd-dd': '0.2',
                    'badge-bg': '#E21C21',
                    'badge-txt': 'color_11',
                    bg: 'transparent',
                    'bg-dd': 'color_11',
                    'boxShadowToggleOn-shd': 'false',
                    'boxShadowToggleOn-shd-dd': 'false',
                    brd: 'color_15',
                    'brd-dd': 'color_15',
                    brw: '0',
                    'brw-dd': '1px',
                    fillcolor: 'color_18',
                    fnt: 'font_8',
                    'fnt-size-dd': '15px',
                    rd: '0px',
                    'rd-dd': '0px',
                    shd: '0 0 0 transparent',
                    'shd-dd': '0 0 0 transparent',
                    txt: 'color_18',
                    'txt-dd': 'color_15',
                    txth: 'color_17',
                    'txth-dd': 'color_14',
                    'txt-slct-dd': 'color_18'
                },
                propertiesSource: {},
                groups: {}
            }
        }
    ],
}

const LOGIN_COMP_LAYOUT = {
    width: 120,
    height: 40,
    scale: 1,
    rotationInDegrees: 0,
    fixedPosition: false,
    x: 0,
    y: 0
}

const LOGIN_COMP_PROPS = {
    type: 'LoginSocialBarProperties',
    metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
    buttonsDirection: 'ltr',
    iconSize: 26,
    dropDownTextAlignment: 'left',
    buttonsAlignment: 'right'
}

const LOGIN_COMP_DATA = {
    type: 'LoginSocialBar',
    loggedInMember: 'avatarOnly',
    loggedOutText: 'Log In',
    showLoggedInText: false,
    loggedInText: 'Hello',
    logOutText: 'Log Out',
    menuItemsRef: null,
    iconItemsRef: null,
    language: 'en',
    role: 'members_login'
}

const LOGIN_COMP_CONNECTIONS = {
    type: 'ConnectionList',
    items: [
        {
            type: 'ConnectionItem',
            controllerId: 'login-bar-controller-id',
            isPrimary: true,
            config: '{}',
            role: 'members-login-bar'
        }
    ]
}

const LOGIN_COMP_RESPONSIVE_APP_WIDGET_DEF = {
    ...LOGIN_COMP_APP_WIDGET,
    components: [{
        type: 'Component',
        skin: 'wysiwyg.viewer.skins.LoginSocialBarSkin',
        componentType: 'wysiwyg.viewer.components.LoginSocialBar',
        data: LOGIN_COMP_DATA,
        props: LOGIN_COMP_PROPS,
        style: LOGIN_COMP_RESPONSIVE_STYLE,
        layout: LOGIN_COMP_LAYOUT,
        layoutResponsive: LOGIN_COMP_RESPONSIVE_LAYOUT,
        connections: LOGIN_COMP_CONNECTIONS
    }]
}
export {LOGIN_COMP_RESPONSIVE_APP_WIDGET_DEF}
